import React, { useState } from "react"
import Layout from "../components/layout"
import { makeStyles } from "@material-ui/core/styles"
import {
  Typography,
  useMediaQuery,
  Grid,
  Tabs,
  Tab,
  withStyles,
  Paper,
  Button,
} from "@material-ui/core"
import Helmet from "react-helmet"
import Bar from "../components/bar"

import JackIcon from "../components/assets/Jack.inline.svg"

const useStyles = makeStyles(theme => ({
  spacer: {
    height: "10vh",
  },
  spacer2: {
    height: "15vh",
  },
  paper: {
    padding: "2rem",
    background: "transparent",
    backdropFilter: "blur(2px)",
    border: "1px solid #404040",
    color: "white",
    fontFamily: "Jost",
    width: "100%",
  },
  headerText: {
    fontSize: "1.2rem",
    fontFamily: "Fira Code",
    marginBottom: "-7px",
  },
  sectionBody: {
    fontFamily: "Jost",
  },
}))

function Section(props, { children }) {
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.headerText}>{props.headerText}</Typography>
      <hr style={{ border: "1px solid #f50057" }} />
      <Typography className={classes.sectionBody}>{props.text}</Typography>
      <br />
    </>
  )
}

export default function CV() {
  const isPhone = useMediaQuery("(max-width: 600px)")
  const classes = useStyles()

  return (
    <Layout tabIndex={1}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Fira+Code&family=Jost&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Grid container justify="center">
        <Grid item sm={7}>
          <Grid container justify="center">
            <Paper className={classes.paper}>
              <Bar name="Jack Burns" contactDetails />
              <div style={{ height: "5vh" }} />
              <Section
                headerText="Summary"
                text={
                  <>
                    Strongly motivated and passionate individual with a keen
                    interest in software development, and a focus on real world
                    projects deployed in the cloud.
                  </>
                }
              />

              <Section
                headerText="Skills"
                text={
                  <>
                    <ul>
                      <li>
                        Front End Frameworks: React, JavaScript, HTML5, CSS,
                        Responsive Design
                      </li>
                      <li>Back End Frameworks: Python, Node.js, SQL </li>
                      <li>
                        Amazon Web Services: Lambda, S3, API Gateway,
                        Serverless, Route53, CloudFront
                      </li>
                    </ul>
                    For a detailed look at my skills, visit www.jaack.dev.
                  </>
                }
              />
              <Section
                headerText="Projects"
                text={
                  <>
                    <ul>
                      <li>
                        jaack.dev - Portfolio website to show achievements and
                        certifications. Hosted serverlessly on Amazon Web
                        Services along with my other websites, resulting in very
                        low running costs.
                      </li>
                      <li>
                        bondcarpentryanddecorating.co.uk - Website for a local
                        group of tradesmen to advertise their services. Built
                        with React and Material UI.
                      </li>
                      <li>
                        teamaveryessex.com - Serverlessly hosted static website
                        for a local charity dedicated to empowering diversity.
                        <ul>
                          <li>
                            Reworked inefficent paper based nomination system to
                            use web based serverless functions, drastically
                            decreasing nomination processing time.
                          </li>
                          <li>
                            Implemented scalable e-commerce solution to enable
                            the sale of their newly published book.
                          </li>
                        </ul>
                      </li>
                    </ul>
                    For more projects and certifications, visit www.jaack.dev.
                  </>
                }
              />
              <Section
                headerText="Work Experience"
                text={
                  <>
                    <ul>
                      <li>M&G PLC - Technology Adoption Specialist</li>
                    </ul>
                    <>
                      I worked for M&G PLC as part of a college work experience
                      placement. Most of my time at this role was spent
                      networking and discussing cloud technology with senior
                      developers, CTOs, and the head engineer. We also discussed
                      Agile development and the systems architecture of M&G.
                    </>
                  </>
                }
              />
              <Section
                headerText="Qualifications"
                text={
                  <>
                    <ul>
                      <li>
                        BTEC IT Level 3 {isPhone ? <br /> : <>-</>} Currently
                        Studying {isPhone ? <br /> : <>-</>} September 2019 -
                        Present
                      </li>
                    </ul>
                  </>
                }
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}
